
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import HybridQuestionnaire from '@/components/analysis/hybrid/HybridQuestionnaire.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { NetworkActionEnum, WorkspaceActionEnum } from '@/store/enums/actions'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { vuexActions } from '@/store/util'

const { NETWORK } = ModuleNames

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  let { workspaceId } = to.params
  if (!Array.isArray(workspaceId)) {
    const store = useStore()
    const userId = store.state[ModuleNames.AUTH][AuthStateEnum.USER]?.id as string
    workspaceId = workspaceId as string
    await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
    await store.dispatch(
      vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE),
      workspaceId
    )
    if (!userId) {
      message.error({
        content: MESSAGE.UNEXPECTED_USER_ID(userId),
        duration: 3
      })
      setTimeout(() => {
        next(PATH.HOME)
      }, 3000)
      return
    }
    next()
  } else {
    message.error({
      content: MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId),
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
  }
}

export default defineComponent({
  components: {
    HybridQuestionnaire
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    return {
      workspaceId
    }
  }
})
