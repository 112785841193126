
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import useAnalysis from '@/components/analysis/composable/analysis'
import useBase from '@/components/analysis/composable/base'
import useHybrid from '@/components/analysis/composable/hybrid-criteria'
import useHybridResults from '@/components/analysis/composable/hybrid-results'
import useHybridSelector from '@/components/analysis/composable/hybrid-selector'
import { CriteriaRowType, ParentRowType } from '@/components/analysis/composable/hybrid-types'
dayjs.extend(relativeTime)

import { gradientMaker } from '@/libs/utils'
import { cssIcon } from '@/styles/common'
import { JobType } from '@/types/database/job'

import HybridFramework, { EVENTS as RES_EVENTS } from './HybridFramework.vue'

export default defineComponent({
  components: {
    HybridFramework
  },
  props: {
    unused: { type: String, default: undefined },
    workspaceId: { type: String, required: true }
  },
  setup(props) {
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)

    const {
      networks,
      loadWorkspace,
      loadNetworks,
      workspace,
      workspaceCreated,
      workspaceModified
    } = useBase(props.workspaceId)

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const { networkMap, currentUser } = useAnalysis(props.workspaceId, JobType.WHATIF, networks)
    const currentUserId = computed(() => currentUser.value?.id)

    const criteriaRows: Ref<CriteriaRowType[]> = ref([])

    const criteriaMap: Ref<Record<string, any>> = ref([])

    watch(criteriaRows, () => {
      const map: Record<string, any> = {}
      criteriaRows.value.forEach(
        ({
          parentKey = '',
          childrenKeys = [],
          utilityVector = [],
          userIdToWeightMap
        }: CriteriaRowType) => {
          map[parentKey] = {
            result: {},
            utilityVector,
            childrenKeys,
            userIdToWeightMap
          }
        }
      )
      criteriaMap.value = map
    })

    const {
      runUpdateResults,
      runCreateResults,
      isPersistingResults,
      currentResultsMap,
      loadResults
    } = useHybridResults(props.workspaceId)

    const { loadCriteria } = useHybrid(
      props.workspaceId,
      currentUser,
      networkMap,
      criteriaRows,
      updateTable,
      onSuccessSaveJob
    )

    const cardStyle = computed(() => ({
      backgroundSize: '100% 32px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: gradientMaker(workspace.value?.name || 'UNTITLED')
    }))

    onMounted(async () => {
      await loadWorkspace()
      await loadNetworks()
      await loadCriteria()
      await loadResults(currentUserId.value)
    })

    const onSelectKeyForChart = (parentKey: string) => {
      selectedParentKey.value = parentKey
    }

    const updateResult = (parentKey: string, result: any) => {
      if (criteriaMap.value[parentKey]) {
        criteriaMap.value[parentKey].result = result
      }
    }

    const selectedScalingMethod: Ref<string> = ref('LINEAR')

    const { selectedParentKey, childrenKeys, refreshResults } = useHybridSelector(
      props.workspaceId,
      currentUserId,
      currentResultsMap,
      true,
      selectedScalingMethod,
      () => {}
    )

    const loadResultsHandler = () => {
      loadResults(currentUserId.value)
    }

    const refreshResultsHandler = () => {
      refreshResults()
    }

    const parentRows: ComputedRef<ParentRowType[]> = computed(() => {
      if (!criteriaRows.value) {
        return []
      }
      return criteriaRows.value
        .filter(({ childrenKeys }) => childrenKeys?.length > 1)
        .map(({ parentKey, childrenKeys, userIdToWeightMap }, index) => {
          return {
            key: index,
            parentKey,
            childrenKeys,
            selected: false,
            userIdToWeightMap
          }
        })
    })

    return {
      currentUser,
      parentRows,
      refreshResultsHandler,
      loadResultsHandler,
      runUpdateResults,
      runCreateResults,
      isPersistingResults,
      currentResultsMap,
      loadResults,
      criteriaRows,
      networkMap,
      currentUserId,
      cardStyle,
      childrenKeys,
      cssIcon,
      loadNetworks,
      workspace,
      workspaceCreated,
      workspaceModified,
      criteriaMap,
      // PAIRWISE_EVENTS,
      updateResult,
      onSelectKeyForChart,
      RES_EVENTS
    }
  }
})
