import { render } from "./AhpView.vue?vue&type=template&id=5f8f5b24"
import script from "./AhpView.vue?vue&type=script&lang=ts"
export * from "./AhpView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5f8f5b24"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5f8f5b24', script)) {
    api.reload('5f8f5b24', script)
  }
  
  module.hot.accept("./AhpView.vue?vue&type=template&id=5f8f5b24", () => {
    api.rerender('5f8f5b24', render)
  })

}

script.__file = "src/views/AhpView.vue"

export default script